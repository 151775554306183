<template>
	<div class="test1"></div>
</template>

<script>
	import login from "@/common/islogin";
	import {
		loginUser
	} from "@/http/api";
	import LStorage from "@/storage/local";
	import {
		Toast
	} from 'vant';

	export default {
		components: {},
		data() {
			return {
				routeObj: {},
				siteInfo: {}
			};
		},
		created() {
			this.routeObj = this.$route.query;
			this.siteInfo = LStorage.getItem('siteInfo')
			if (this.routeObj.code && this.siteInfo.appid) {
				this.login();
			} else {
				this.$toast.fail('登录错误 - 10001')
			}
		},
		mounted() {},
		methods: {
			// 登录方法
			async login() {
				let res = await loginUser({
					data: {
						site_code: this.siteInfo.site_code,
						appid: this.siteInfo.appid,
						code: this.routeObj.code
					}
				});
				if (res.code == 200) {
					//  用户登录成功
					LStorage.setItem('userToken', res.data.token)
					LStorage.setItem('userInfo', res.data.userinfo)
					this.$router.push(this.routeObj.state);
				} else if (res.code == 201) {
					// 存储后端返回的attach,用于注册
					LStorage.setItem('regAttach', res.data.attach)
					//  执行用户注册
					login.execRegin(this.routeObj.state);
				}
			}
		}
	};
</script>


<style lang="scss" scoped>
	.test1 {}
</style>
